@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;700;800&display=swap');

body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
